.breadcrumbs {
  position: relative;
  padding: 2rem 0 0;
  z-index: 2;
  @include tabletP {
    padding: 2.6rem 0 1.3rem;
  }
  @include desk {
    padding-bottom: 0;
  }
  &__home {
    margin-right: 0.3rem;
    font-size: 1.3rem;
    white-space: nowrap;
    display: inline;
    @include tabletP {
      margin-right: 0.5rem;
      font-size: 1.2rem;
    }
    @include desk {
      margin-right: 0.2rem;
    }
  }
  &__items {
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: inline;
    @include tabletP {
      line-height: unset;
    }
  }
  &__link {
    color: $thirdGrey;
    cursor: pointer;
    line-height: 1.8rem;
    margin-right: 0.5rem;
    transition: $transition-base;
    @include tabletP {
      margin-right: 0.6rem;
    }
    @include desk {
      margin-right: 0.5rem;
    }
    &:hover {
      color: $hoverOrange;
    }
    &--mobile {
      margin-left: 0;
      @include tabletP {
        display: none;
      }
    }
    &--tablet {
      display: none;
      @include tabletP {
        margin-left: 0;
        display: inline-block;
      }
    }
    &--current {
      color: $primaryBlack;
      pointer-events: none;
      cursor: unset;
      margin-right: 0;
    }
    a + a {
      margin-left: 0.5rem;
    }
  }
  &__icon {
    display: table-cell;
    vertical-align: middle;
    display: inline-block;
    font-size: 0.4rem;
    transform: rotate(-90deg);
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
    @include desk {
      margin-left: 0.2rem;
    }
    &::before {
      color: $thirdGrey;
    }
  }
}
