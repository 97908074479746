.error {
  margin-top: 3.2rem;
  margin-bottom: 8.5rem;
  @include tabletP {
    display: flex;
    margin-top: 6rem;
    margin-bottom: 6rem;
    align-items: center;
  }
  @include desk {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  h2 {
    color: $primaryViolet;
    margin-bottom: 1.6rem;
    @include desk {
      margin-bottom: 1.3rem;
    }
  }
  p {
    margin-bottom: 1.6rem;
  }
  &__img {
    width: 29.7rem;
    margin: 1.5rem auto 0;
    @include tabletP {
      margin: 0;
      order: -1;
      flex-basis: 32.5rem;
    }
    @include desk {
      flex-basis: 45rem;
    }
  }
  &__text {
    @include tabletP {
      flex-basis: calc(100% - 32.5rem);
      padding-left: 4rem;
    }
    @include desk {
      flex-basis: calc(100% - 45rem);
      padding-left: 6rem;
    }
  }
}
